.wrapper {
  display: flex;
  flex-wrap: wrap;
  @media screen and (max-width: 1200px) {
    justify-content: space-between;
    &>* {
      flex-basis: calc(33% - 30px) !important;
    }
  }
  @media screen and (max-width: 960px) {
    justify-content: space-between;
    &>* {
      flex-basis: calc(50% - 30px) !important;
    }
  }
  @media screen and (max-width: 620px) {
    justify-content: space-between;
    &>* {
      flex-basis: calc(100% - 30px) !important;
    }
  }
}

.placeholder {
  flex-basis: 350px;
  flex-shrink: 1;
  padding: 15px;
}
