.app {
  height: 100%;
  overflow: auto;
}

.contentContainer {
  width: 100%;
  .content {
    flex-grow: 1;
  }
  &>* {
    transition: .4s all;
  }
  @media screen and (max-width: 1200px) {
    overflow: hidden;
    &>* {
      transform: translateX(-330px);
    }
    &.Home {
      &>* {
        transform: translateX(-330px);
      }
    }
    &.Menu {
      &>* {
        transform: translateX(0);
      }
    }
    &.Search {
      &>* {
        transform: translateX(-540px);
      }
    }
    .content {
      min-width: 100vw;
      max-width: 100vw;
      transition: 0.4s all;
    }
  }
}

.wrapper {
  display: flex;
  flex-wrap: wrap;
  @media screen and (max-width: 1200px) {
    justify-content: space-between;
    &>* {
      flex-basis: calc(33% - 30px) !important;
    }
  }
  @media screen and (max-width: 960px) {
    justify-content: space-between;
    &>* {
      flex-basis: calc(50% - 30px) !important;
    }
  }
  @media screen and (max-width: 620px) {
    justify-content: space-between;
    &>* {
      flex-basis: calc(100% - 30px) !important;
    }
  }
}

.panel {
  -ms-flex-preferred-size: 320px;
      flex-basis: 320px;
  width: 320px;
}
